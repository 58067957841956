export const ArchiveIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none'>
    <path fill='#fff' fillOpacity={0.01} d='M0 0h16v16H0z' />
    <path
      fill='#211F26'
      fillRule='evenodd'
      d='M3.53 1.067c-.404 0-.774.228-.954.59L1.123 4.56a.533.533 0 0 0-.056.239v9.067c0 .589.477 1.066 1.066 1.066h11.734c.589 0 1.066-.477 1.066-1.066V4.8a.534.534 0 0 0-.056-.239l-1.453-2.905c-.18-.361-.55-.59-.954-.59H3.53Zm0 1.066h3.937v2.134H2.463L3.53 2.133Zm5.003 2.134V2.133h3.937l1.067 2.134H8.533ZM8 5.333h5.867v8.534H2.133V5.333H8ZM5.867 7.467a.533.533 0 0 0 0 1.066h4.266a.533.533 0 1 0 0-1.066H5.867Z'
      clipRule='evenodd'
    />
  </svg>
);
