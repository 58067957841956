import Link from 'next/link';

import { UploadIcon } from './icons/UploadIcon';

export const SupportButtonSection = () => {
  return (
    <div className='flex flex-col w-full gap-4'>
      <Link
        href={'/support/billupload'}
        className='group hover:bg-[#3D155D] flex h-full lg:h-[56px] lg:overflow-hidden lg:transition-height lg:delay-150 lg:duration-500 lg:ease-in-out lg:hover:h-[98px] flex-col text-center justify-around lg:justify-start w-auto lg:w-full items-center rounded-[8px] lg:gap-0 gap-3 lg:p-0 p-2 bg-[#29004C] text-[#FDFCFD]'
      >
        <div className='px-2 pt-2 lg:p-0 flex text-[18px] justify-center gap-3 items-center lg:min-h-[56px]'>
          Upload a bill
          <UploadIcon className='w-4 h-4 text-white' />
        </div>
        <p className='px-2 pb-3 lg:pt-1 lg:flex lg:justify-center lg:items-start lg:h-[42px] text-[14px] text-center'>
          Submit bills for review and submission
        </p>
      </Link>
      <div className='grid w-full h-full grid-cols-2 grid-rows-1 gap-4 lg:flex lg:items-stretch'>
        <Link
          href={'/support/previsit'}
          className='group hover:bg-[#F5EFFF] flex h-full lg:h-[56px] lg:overflow-hidden lg:transition-height lg:delay-150 lg:duration-500 lg:ease-in-out lg:hover:h-[112px] flex-col text-center justify-around lg:justify-start w-auto lg:w-full text-[16px] border-[1.5px] border-[#45007F] items-center rounded-[8px] lg:gap-0 gap-3 lg:p-0 p-2 bg-white text-[#29004C]'
        >
          <p className='px-2 lg:py-3 lg:flex lg:justify-center lg:items-center lg:min-h-[56px]'>
            Benefits & Cost Check
          </p>
          <p className='px-2 lg:pb-3 lg:flex lg:justify-center lg:items-center lg:h-[56px] text-[12px] text-center'>
            e.g. How much will I pay for physical therapy?
          </p>
        </Link>
        <Link
          href={'/support/postvisit'}
          className='group hover:bg-[#F5EFFF] flex h-full lg:h-[56px] lg:overflow-hidden lg:transition-height lg:delay-150 lg:duration-500 lg:ease-in-out lg:hover:h-[112px] flex-col text-center justify-around lg:justify-start w-auto lg:w-full text-[16px] border-[1.5px] border-[#45007F] items-center rounded-[8px] bg-white text-[#29004C]'
        >
          <p className='px-2 lg:py-3 lg:flex lg:justify-center lg:items-center lg:min-h-[56px]'>
            Post-visit Support
          </p>
          <p className='px-2 lg:pb-3 lg:flex lg:justify-center lg:items-center lg:h-[56px] text-[12px] text-center'>
            e.g. Why was my claim denied?
          </p>
        </Link>
      </div>
    </div>
  );
};
