export const MinusCircleIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none'>
    <path fill='#fff' fillOpacity={0.01} d='M0 0h16v16H0z' />
    <path
      fill='#211F26'
      fillRule='evenodd'
      d='M8 .935a7.064 7.064 0 1 0 0 14.129A7.064 7.064 0 0 0 8 .935ZM1.949 8A6.051 6.051 0 1 1 14.05 8 6.051 6.051 0 0 1 1.949 8ZM4.8 7.467a.533.533 0 0 0 0 1.066h6.4a.533.533 0 1 0 0-1.066H4.8Z'
      clipRule='evenodd'
    />
  </svg>
);
