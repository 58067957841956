export const HalfCircleCheckMark = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={20} fill='none'>
    <g clipPath='url(#a)'>
      <path
        fill='#0D3D38'
        d='M14.932 4.357a.603.603 0 0 0-.85.059l-6.714 7.702-2.801-2.646a.603.603 0 0 0-.828.877l3.258 3.077a.602.602 0 0 0 .868-.042l7.126-8.176a.603.603 0 0 0-.059-.85Z'
      />
      <path
        fill='#031109'
        d='M15.397 9.397a.603.603 0 0 0-.603.603A6.802 6.802 0 0 1 8 16.794 6.802 6.802 0 0 1 1.206 10 6.802 6.802 0 0 1 8 3.206.603.603 0 1 0 8 2c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8a.603.603 0 0 0-.603-.603Z'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 2h16v16H0z' />
      </clipPath>
    </defs>
  </svg>
);
