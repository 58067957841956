export const RightArrowIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none'>
    <path fill='#fff' fillOpacity={0.01} d='M0 0h16v16H0z' />
    <path
      fill='#211F26'
      fillRule='evenodd'
      d='M8.69 3.356a.533.533 0 0 1 .754 0l4.266 4.267a.533.533 0 0 1 0 .754l-4.266 4.267a.533.533 0 0 1-.754-.755l3.356-3.356h-9.38a.533.533 0 0 1 0-1.066h9.38L8.69 4.11a.533.533 0 0 1 0-.754Z'
      clipRule='evenodd'
    />
  </svg>
);
