export const InfoIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={20} fill='none'>
    <path fill='#fff' fillOpacity={0.01} d='M0 2h16v16H0z' />
    <path
      fill='#101D46'
      fillRule='evenodd'
      d='M8 2.935a7.064 7.064 0 1 0 0 14.129A7.064 7.064 0 0 0 8 2.935ZM1.949 10a6.051 6.051 0 1 1 12.102 0 6.051 6.051 0 0 1-12.102 0Zm6.85-3.2a.8.8 0 1 1-1.6 0 .8.8 0 0 1 1.6 0ZM6.4 8.4H8c.295 0 .533.239.533.533v3.734H9.6v1.066H6.4v-1.066H7.467v-3.2H6.4V8.4Z'
      clipRule='evenodd'
    />
  </svg>
);
